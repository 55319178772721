import React from "react";
import FormItem, { FormItemProps } from "./FormItem";

type Props = React.InputHTMLAttributes<HTMLInputElement> & FormItemProps;

const Input: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(({ id, label, description, ...others }, ref) => {
  return (
    <FormItem id={id} label={label} description={description} required={others.required}>
      <input
        ref={ref}
        id={id}
        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
        {...others}
      />
    </FormItem>
  );
});
Input.displayName = "Input";

export default Input;
