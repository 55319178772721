import React, { ReactNode } from "react";

export type FormItemProps = {
  label?: string;
  description?: ReactNode;
  id?: string;
  required?: boolean;
  className?: string;
};
const FormItem: React.FC<FormItemProps> = ({ id, label, className = "mb-6", description, required, children }) => {
  return (
    <div className={className}>
      {label && (
        <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          {label} {required && <span className="text-red-500">*</span>}
        </label>
      )}
      {children}
      {description && <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">{description}</p>}
    </div>
  );
};

export default FormItem;
