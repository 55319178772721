import React, { useState } from "react";
import { supabase } from "../../../common/helpers/supabase";
import { followerWatchList } from "../../../common/data/coinlist";

import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import Button from "../../../components/UI-Elements/Button";
import Input from "../../../components/UI-Elements/Form/Input";
import Title from "@components/UI-Elements/Typography/Title";

const fetchCoingecko = (coin: string) => fetch(`https://api.coingecko.com/api/v3/coins/${coin}?sparkline=false`).then((res) => res.json());

const Playground = () => {
  const [items, setItems] = useState<any[]>([]);
  const [coinId, setCoinId] = useState("");
  const onClick = () => {
    console.log(followerWatchList);
    followerWatchList.map((item) => addItem(item));
  };

  const updateName = () => {
    items.map((item) => {
      fetchCoingecko(item.coingecko_id)
        .then((res) => res.name)
        .then((name) => {
          supabase
            .from("coins")
            .update({
              name,
            })
            .match({ id: item.id })
            .then((res) => console.log(res));
        });
    });
  };

  const addItem = async (item: string) => {
    const res = await supabase
      .from("coins")
      .select("name, coingecko_id")
      .match({ coingecko_id: item })
      .then((res) => res.data);
    
      if (res && res.length === 0) {
        supabase
          .from("coins")
          .insert({
            coingecko_id: item,
          })
          .then((res) => console.log(`added ${item}`, res));
      } else {
        console.log(res);
      }
  };

  const getItem = async (item: string) => {
    const res = await supabase
      .from("coins")
      .select("*")
      .match({ coingecko_id: item })
      .then((res) => res.data);
    console.log(item, res);
    
      setItems(res || []);
  };

  const onFetch = () => {
    supabase
      .from("coins")
      .select("id,name,coingecko_id")
      .then((res) => {
        if (res.status === 200) {
          setItems(res.data || [])
        } else {
          console.log(res);
        }
      })
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => setCoinId(e.target.value);
  
  return (
    <Layout>
      <SEO title="Playground" robots="noindex" />
      <Title>Playground</Title>
      <Title level={3}>Item hinzufügen</Title>
      <div className="flex justify-between">
        <Input onChange={onChange} placeholder="coingecko_id"/>
        <div>
          <Button onClick={() => addItem(coinId)} className="mr-2">Add Item</Button>
          <Button onClick={() => getItem(coinId)}>Get Item</Button>
        </div>
      </div>
      {items.map((item, index) => (
        <div key={index}>{JSON.stringify(item)}</div>
      ))}
      <div className="grid grid-cols-2 gap-2">
        <Button onClick={onClick}>Add list of Item</Button>
        <Button onClick={updateName}>Update Names</Button>
        <Button onClick={onFetch}>Load all</Button>
      </div>
    </Layout>
  );
};

export default Playground;
