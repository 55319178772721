import uniq from "lodash/uniq";

export const followerWatchList = uniq([
    "bitcoin",
    "ripple",
    // smart contract
    "ethereum",
    "binancecoin",
    "solana",
    "cardano",
    "avalanche-2",
    "terra-luna",
    "polkadot",
    "matic-network",
    "near",
    "chainlink",
    "tron",
    "algorand",
    "stellar",
    "vechain",
    "internet-computer",
    "tezos",
    "defichain",
    "blockstack",
    // gaming
    "axie-infinity",
    "decentraland",
    "the-sandbox",
    "theta-token",
    "flow",
    "apecoin",
    "gala",
    "enjincoin",
    "chiliz",
    "stepn",
    "ecomi",
    "smooth-love-potion",
    "wax",
    "illuvium",
    "mobox",
    "origin-protocol",
    "superrare",
    "handshake",
    "rarible",
    // meme
    "dogecoin", "shiba-inu",
    "axie-infinity", "decentraland", "the-sandbox", "gala", "wax", "mobox", "illuvium", "aavegotchi", "ultra", "seedify-fund", "chain-guardians", "derace", "netvrk", "virtue-poker", "altura", "ufo-gaming", "mines-of-dalarnia", "sidus",
    // dex
    "uniswap",
    "thorchain",
    "osmosis",
    "pancakeswap-token",
    "havven",
    "loopring",
    "curve-dao-token",
    "sushi",
    "gnosis",
    "1inch",
    "bancor",
    "0x",
    "dydx",
    "perpetual-protocol",
    "raydium",
    "dodo",
    "balancer",
    "ellipsis",
    "apeswap-finance",
    "openocean",
    // cex
    "binancecoin",
    "crypto-com-chain",
    "ftx-token",
    "okb",
    "kucoin-shares",
    "huobi-token",
    "gatechain-token",
    "link",
    "hotbit-token",
    "bitpanda-ecosystem-token"
])